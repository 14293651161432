import React from "react"
import { graphql } from "gatsby"
import Meta from "../components/common/Meta"
import styled from "styled-components"
import rehypeReact from "rehype-react"
import WorkLayout from "../components/work/WorkLayout"
import WorkHeader from "../components/work/WorkHeader"
import {
  AboutParagraph,
  Bold,
  ListItem,
  TitleSecondary,
} from "../components/work/WorkStyles"
import { Fragment } from "react"
import CaseCard from "../components/work/CaseCard"

const PositionArticle = styled.article`
  grid-area: jobcontent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  position: relative;

  &:before {
    @media (min-width: 64em) {
      content: "";
      position: absolute;
      left: 0;
      width: 5px;
      height: 1050px;
      background-color: var(--clr-secondary-dark);
    }
  }

  @media (min-width: 64em) {
    margin-top: 5.125rem;
    margin-left: 1.845rem;
    padding-left: 4rem;
    padding-right: 5.31rem;
    margin-bottom: 9.5rem;
  }
`

const StyledBlockquote = styled.blockquote`
  p {
    color: #d2085d;
    font-family: var(--ff-secondary);
    font-weight: 700;
    font-size: 1.5em;
    line-height: 39px;
    margin: 0;
  }
  @media (min-width: 40em) {
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
    float: right;
  }
`

const Blockquote = ({ children }) => {
  const quoteLength = children[1].props.children[0].length
  const maxwidth = quoteLength < 120 ? `${25}ch` : `${35}ch`
  return <StyledBlockquote maxwidth={maxwidth}>{children}</StyledBlockquote>
}

const StyledList = styled.ul`
  list-style-type: none;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: TitleSecondary,
    h3: TitleSecondary,
    p: AboutParagraph,
    blockquote: Blockquote,
    ul: StyledList,
    li: ListItem,
    em: Bold,
  },
  Fragment,
}).Compiler

// eslint-disable-next-line no-unused-vars
export const Head = ({ data, pageContext }) => (
  <Meta
    title={data?.workMd?.frontmatter.mainTitle}
    description={data?.workMd?.frontmatter.subheading}
  />
)

const PositionApplyTemplate = ({ data }) => {
  let { htmlAst } = data.workMd

  const { mainTitle, subheading } = data?.workMd?.frontmatter

  return (
    <WorkLayout data={data}>
      <PositionArticle>
        <WorkHeader title={mainTitle} subheading={subheading} />
        <CaseCard caseData={data.workMd.frontmatter} />
        {renderAst(htmlAst)}
      </PositionArticle>
    </WorkLayout>
  )
}

export default PositionApplyTemplate

export const query = graphql`
  query WorkPage($slug: String) {
    workMd: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        mainTitle
        subheading
        slug
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        profileImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        client
        industry
        keywords
        length
        employeeName
        positionName
        phone
        linkedIn
        email
      }
      htmlAst
      headings {
        value
      }
    }
  }
`
